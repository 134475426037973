import { action, createModule, mutation } from 'vuex-class-component'
import { RoleId } from '../src/services/RoleApiService'
import { useRoleApiService } from '~/src/composables/dependency'
import { Role } from '~/src/services/RoleApiService'

const VuexModule = createModule({
  namespaced: 'roles',
  strict: false,
  target: 'nuxt',
})

export class RoleStore extends VuexModule {
  roles: Role[] = []
  rolesFetched = false

  @mutation
  SET_ROLES(roles: Role[]): void {
    this.roles = roles
  }

  @mutation
  SET_ROLES_FETCHED(): void {
    this.rolesFetched = true
  }

  @mutation
  ADD_ROLE(role: Role): void {
    this.roles.push(role)
  }

  @mutation
  DELETE_ROLE(role: Role): void {
    const index = this.roles.findIndex(
      (inspected: Role) => inspected.id?.toString() === role.id?.toString()
    )

    if (index >= 0) {
      this.roles.splice(index, 1)
    }
  }

  @mutation
  UPDATE_ROLE(updated: Role): void {
    const index = this.roles.findIndex(
      (inspected: Role) => inspected.id?.toString() === updated.id?.toString()
    )

    if (index >= 0) {
      this.roles.splice(index, 1, updated)
    }
  }

  get rolesById(): Record<string, Role> {
    return this.roles == null
      ? {}
      : Object.fromEntries(this.roles.map((role: Role) => [role.id, role]))
  }

  @action
  async fetchRoles(): Promise<void> {
    if (!this.rolesFetched) {
      this.SET_ROLES(await useRoleApiService().list())
      this.SET_ROLES_FETCHED()
    }
  }

  @action
  async addRole(role: Role): Promise<RoleId> {
    const id = await useRoleApiService().create(role)
    this.ADD_ROLE({ ...role, id })
    return id
  }

  @action
  async updateRole(role: Role): Promise<void> {
    const result = await useRoleApiService().update(role)
    this.UPDATE_ROLE(role)
    return result
  }

  @action
  async deleteRole(role: Role): Promise<void> {
    const result = await useRoleApiService().delete(role)
    this.DELETE_ROLE(role)
    return result
  }
}
