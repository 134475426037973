import { Auth } from '@nuxtjs/auth-next/dist/runtime'
import { inject, injectable } from 'inversify'
import SYMBOLS from '~/src/dependency_injection/Symbols'
import { PermissionService } from '~/src/services/permissions/PermissionService'
import type { Vxm } from '~/types/portal'

@injectable()
export class RolePermissionsService extends PermissionService {
  public static DEFAULT_GROUP_PERMISSIONS = {
    READ: 1,
    EDIT: 2,
    CREATE: 4,
    DELETE: 8,
  }

  public constructor(
    @inject(SYMBOLS.Auth) public readonly auth: Auth,
    @inject(SYMBOLS.Vxm) protected readonly vxm: Vxm
  ) {
    super(auth, vxm, '6WG-0-', 1)
  }

  public isCreateNewAllowed(): boolean {
    return this.hasPermission(
      RolePermissionsService.DEFAULT_GROUP_PERMISSIONS.CREATE
    )
  }

  public isReadAllowed(): boolean {
    return this.hasPermission(
      RolePermissionsService.DEFAULT_GROUP_PERMISSIONS.READ
    )
  }

  public isEditAllowed(): boolean {
    return this.hasPermission(
      RolePermissionsService.DEFAULT_GROUP_PERMISSIONS.EDIT
    )
  }

  public isDeleteAllowed(): boolean {
    return this.hasPermission(
      RolePermissionsService.DEFAULT_GROUP_PERMISSIONS.DELETE
    )
  }
}
