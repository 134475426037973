import { pathToRegexp } from 'path-to-regexp'
import { action, createModule, mutation } from 'vuex-class-component'
import type { NuxtRuntimeConfig } from '@nuxt/types/config/runtime'
import { makeAssetUrl } from '~/src/composables/useAsset'
import { JobTitlesConfig } from '~/types/jobTitles'
import {
  ConfigForType,
  DefaultPage,
  DefaultPageConfig,
  GeneratedPortalPageType,
  HasPermissionBit,
  IframePage,
  ModulePage,
  ModulePageConfig,
  NavigationItemConfig,
  NavigationPage,
  Page,
  PageForType,
  PermissionObject,
  Plugin,
  PortalConfig,
  PortalPageType,
  SubNavigationItemConfig,
  TrackingPlugins,
} from '~/types/portal-config'
import { setVersion } from '~/helpers/makeAssetUrl'
import { usePathMapping } from '~/src/composables/usePathMapping'

import { getGroupAndBit } from '~/helpers/config/getGroupAndBit'
import {
  basePortalConfig,
  prepareNavigation,
} from '~/helpers/config/prepareNavigation'
import type { NavigationItem } from '~/src/composables/useNavigation'

const VuexModule = createModule({
  namespaced: 'portalConfig',
  strict: false,
  target: 'nuxt',
})

export type InternalNavigationItem = Omit<
  NavigationItemConfig,
  'permission'
> & {
  exact?: boolean
  permission?: PermissionObject
  type?: NavigationItem['type']
}

export type InternalSubNavigationItem = Omit<
  SubNavigationItemConfig,
  'permission'
> & {
  permission?: PermissionObject
}

export type MergedConfig = Omit<
  PortalConfig &
    Required<Pick<PortalConfig, 'title' | 'fuchsbau' | 'registrationContact'>>,
  'navigation'
> & {
  navigation: InternalNavigationItem[]
}

interface SetConfigPayload {
  config: MergedConfig
  jobTitles: JobTitlesConfig
}

interface Metadata {
  version: string
  // eslint-disable-next-line camelcase
  build_date?: string
}

interface PathMapping {
  plain: Record<string, string>
  regexp: Record<string, RegExp>
}

function assertFetched(store: {
  fetched: boolean
  config: MergedConfig | null
}): asserts store is { fetched: true; config: MergedConfig } {
  if (!store.fetched) {
    throw new Error('PortalConfigStore: config not fetched')
  }
}

function permissionBitToObject(
  hasPermissionBit: HasPermissionBit,
  service: string
): PermissionObject | undefined {
  return hasPermissionBit.permission !== undefined
    ? {
        service,
        ...getGroupAndBit(hasPermissionBit.permission),
      }
    : undefined
}

function createPageProcessor(pages: Record<string, Page>, service: string) {
  return ([path, page]: [string, DefaultPageConfig]) => {
    pages[path] = {
      ...page,
      permission: permissionBitToObject(page, service),
      title: page.title,
      type: PortalPageType.DEFAULT,
    }
  }
}

function createProcessorForType<T extends GeneratedPortalPageType>(
  type: T,
  service: string
) {
  return (page: ConfigForType<T>) =>
    [
      page.route.path,
      {
        ...page,
        type,
        permission: permissionBitToObject(page, service),
      } as unknown as PageForType<T>,
    ] as const
}

export class PortalConfigStore extends VuexModule {
  config: MergedConfig | null = null
  jobTitleConfig: JobTitlesConfig | null = null
  meta: Metadata | null = null
  fetched = false
  presetModules: ModulePageConfig[] = []

  staticNavService = ''
  dynamicNavService = ''

  @mutation
  SET_META(meta: Metadata): void {
    this.meta = meta
    setVersion(this.meta.build_date ?? this.meta.version)
  }

  @mutation
  SET_CONFIG({ config, jobTitles }: SetConfigPayload): void {
    this.config = { ...config }
    this.jobTitleConfig = { ...jobTitles }
  }

  @mutation
  SET_FETCHED(): void {
    this.fetched = true
  }

  @mutation
  SET_PRESET_MODULES(modules: ModulePageConfig[]): void {
    this.presetModules = modules
  }

  @mutation
  SET_STATIC_NAV_SERVICE(service: string): void {
    this.staticNavService = service
  }

  @mutation
  SET_DYNAMIC_NAV_SERVICE(service: string): void {
    this.dynamicNavService = service
  }

  @action
  async fetchMeta(): Promise<void> {
    if (this.meta === null) {
      const response = await fetch('/meta.json')
      const meta = await response.json()

      this.SET_META(meta)
    }
  }

  // eslint-disable-next-line require-await
  @action
  async initializePresetModules(modulePublicPath: string) {
    this.SET_PRESET_MODULES([
      {
        basePath: '/backoffice/usermanagement/m/',
        route: {
          path: '/backoffice/usermanagement/m/(.*)',
          name: 'management-module',
        },
        rootUrl: `${modulePublicPath}/management`,
        css: 'style.css',
        src: 'src/main.ts',
        format: 'esm',
        manifest: 'manifest.json',
      },
      {
        basePath: '/admin/',
        route: {
          path: '/admin/(.*)',
          name: 'admin-module',
        },
        rootUrl: `${modulePublicPath}/admin`,
        css: 'style.css',
        src: 'src/main.ts',
        format: 'esm',
        manifest: '.vite/manifest.json',
        permission: 6,
      },
    ])
  }

  @action
  async initializePlugins(apiUrl: string) {
    const plugins = (await (
      await fetch(`${apiUrl}/plugins`)
    ).json()) as Plugin[]

    return {
      googleAnalytics: plugins.find(
        (plugin) => plugin.name === 'googleAnalytics'
      ),
      matomo: plugins.find((plugin) => plugin.name === 'matomo'),
      leadInfo: plugins.find((plugin) => plugin.name === 'leadInfo'),
    } as TrackingPlugins
  }

  @action
  async initialize(runtimeConfig: NuxtRuntimeConfig): Promise<void> {
    if (this.fetched) {
      return
    }

    const {
      apiUrl,
      assetsURL,
      modulePublicPath,
      NavPermissionService,
      StaticNavPermissionService,
    } = runtimeConfig

    this.SET_STATIC_NAV_SERVICE(StaticNavPermissionService)
    this.SET_DYNAMIC_NAV_SERVICE(NavPermissionService)

    const configPromise: Promise<
      [PortalConfig, JobTitlesConfig, TrackingPlugins]
    > = Promise.all([
      (await fetch(makeAssetUrl('/portal.config.json', assetsURL))).json(),
      (await fetch(makeAssetUrl('/job-titles.json', assetsURL))).json(),
      await this.initializePlugins(apiUrl),
    ])

    const modulePromise = this.initializePresetModules(modulePublicPath)

    const [portalConfig, jobTitles, trackingPlugins] = await configPromise

    const navigation = prepareNavigation(
      portalConfig.navigation,
      StaticNavPermissionService,
      NavPermissionService
    )

    // merge runtime config
    const mergedConfig: MergedConfig = {
      ...portalConfig,
      navigation,
      tracking: trackingPlugins,
      title: portalConfig.title ?? runtimeConfig.viewportName,
      fuchsbau: {
        id: portalConfig.fuchsbau?.id ?? runtimeConfig.fuchsbauId,
        name: portalConfig.fuchsbau?.name ?? runtimeConfig.fuchsbauName,
      },
      registrationContact:
        portalConfig.registrationContact ??
        runtimeConfig.registration_info_contact,
    }

    await modulePromise
    this.SET_CONFIG({ config: mergedConfig, jobTitles })
    this.SET_FETCHED()
  }

  get pages() {
    const pages: Record<string, DefaultPage> = {}

    Object.entries(basePortalConfig.pages).forEach(
      createPageProcessor(pages, this.staticNavService)
    )

    Object.entries(this.config?.pages ?? {}).forEach(
      createPageProcessor(pages, this.dynamicNavService)
    )

    return pages
  }

  get iframes() {
    const iframes: Record<string, IframePage> = Object.fromEntries(
      this.config?.iframes.map(
        createProcessorForType(PortalPageType.IFRAME, this.dynamicNavService)
      ) ?? []
    )

    return iframes
  }

  get navigationPages() {
    const navigationPages: Record<string, NavigationPage> = Object.fromEntries(
      this.config?.navigationPages.map((pageConfig) => [
        pageConfig.route.path,
        {
          ...pageConfig,
          type: PortalPageType.NAVIGATION,
          permission: permissionBitToObject(pageConfig, this.dynamicNavService),
          items: pageConfig.items.map((subItem) => ({
            ...subItem,
            permission: permissionBitToObject(subItem, this.dynamicNavService),
          })),
        },
      ]) ?? []
    )

    return navigationPages
  }

  get modules() {
    const modules: Record<string, ModulePage> = Object.fromEntries([
      ...this.presetModules.map(
        createProcessorForType(PortalPageType.MODULE, this.staticNavService)
      ),
      ...(this.config?.modules.map(
        createProcessorForType(PortalPageType.MODULE, this.dynamicNavService)
      ) ?? []),
    ])

    return modules
  }

  get mapping() {
    const mapping: PathMapping = {
      plain: {},
      regexp: {},
    }

    function addMapping(path: string) {
      if (path.startsWith('http')) {
        mapping.plain[path] = path
      } else {
        mapping.regexp[path] = pathToRegexp(path)
      }
    }

    Object.keys(this.pages)
      .concat(
        Object.keys(this.iframes),
        Object.keys(this.navigationPages),
        Object.keys(this.modules),
        this.config?.navigation.map((item) =>
          typeof item.target === 'string' ? item.target : item.target.path ?? ''
        ) ?? [],
        Object.values(this.navigationPages).flatMap((page) =>
          page.items.map((subItem) =>
            typeof subItem.target === 'string'
              ? subItem.target
              : subItem.target.path ?? ''
          )
        )
      )
      .forEach(addMapping)

    return mapping
  }

  get allPages(): Record<string, Page> {
    return {
      ...this.pages,
      ...this.modules,
      ...this.iframes,
      ...this.navigationPages,
    }
  }

  get permissions(): Record<string, PermissionObject> {
    const permissions: Record<string, PermissionObject> = {}

    Object.entries(this.allPages).forEach(([path, page]) => {
      if (page.permission) {
        permissions[path] = page.permission
      }
    })

    const { extractPathFromRawLocation } = usePathMapping({
      portalConfig: this,
    })

    this.config?.navigation?.forEach((item) => {
      if (!item.permission) {
        return
      }

      const path = extractPathFromRawLocation(item.target)

      if (!path || permissions[path] !== undefined) {
        return
      }

      permissions[path] = item.permission
    })

    Object.values(this.navigationPages).forEach((page) => {
      page.items.forEach((subItem) => {
        if (!subItem.permission) {
          return
        }

        const path = extractPathFromRawLocation(subItem.target)

        if (!path) {
          return
        }

        if (permissions[path] !== undefined) {
          return
        }

        permissions[path] = subItem.permission
      })
    })

    return permissions
  }

  get jobTitlesEnabled(): boolean {
    return this.jobTitleConfig?.enabled ?? false
  }

  get jobTitles(): JobTitlesConfig['items'] {
    return this.jobTitleConfig?.enabled ? this.jobTitleConfig?.items ?? [] : []
  }

  get title(): string {
    assertFetched(this)
    return this.config.title
  }

  get fuchsbauName(): string {
    assertFetched(this)
    return this.config.fuchsbau.name
  }

  get fuchsbauId(): string {
    assertFetched(this)
    return this.config.fuchsbau.id
  }

  get registrationContact(): string {
    assertFetched(this)
    return this.config.registrationContact
  }
}
