import type { RawLocation, RouteConfig } from 'vue-router'
import { InternalSubNavigationItem } from '~/store/portalConfig'

export type PortalRouteConfig = Partial<
  Omit<RouteConfig, 'name' | 'path' | 'component'>
> &
  Required<Pick<RouteConfig, 'name' | 'path'>>

export enum PortalPageType {
  DEFAULT,
  MODULE,
  IFRAME,
  NAVIGATION,
}

export interface PermissionObject {
  service: string
  group: number
  bit: number
}

export type GeneratedPortalPageType =
  | PortalPageType.MODULE
  | PortalPageType.IFRAME
  | PortalPageType.NAVIGATION

export interface HasPermissionBit {
  permission?: number
}

export interface HasPermissionObject {
  permission?: PermissionObject
}

export interface Routed {
  route: PortalRouteConfig
}

export interface Titled {
  title: string
}

export interface Labelled {
  label: string
}

export interface ModulePageProperties extends Routed {
  rootUrl?: string
  endpoints?: Record<string, string>
  src: string
  css: string
  basePath?: string
  format?: 'umd' | 'esm'
  manifest?: string
}

export interface ModulePageConfig
  extends ModulePageProperties,
    HasPermissionBit {}

export interface IframePageProperties extends Routed, Titled {
  src: string
}

export interface IframePageConfig
  extends IframePageProperties,
    HasPermissionBit {}

export type DefaultPageProperties = Titled

export interface DefaultPageConfig
  extends DefaultPageProperties,
    HasPermissionBit {}

export interface NavigationItemConfig {
  target: RawLocation
  label: string
  ctaText?: string
  icon: string
  external?: boolean
  openInNewTab?: boolean
  permission?: number
  group?: number
}

export interface SubNavigationItemConfig extends NavigationItemConfig {
  primary?: boolean
  description?: string
  download?: boolean
}

export interface NavigationPageProperties extends Routed, Titled {
  description: string
  items: SubNavigationItemConfig[]
}

export interface NavigationPageConfig
  extends NavigationPageProperties,
    HasPermissionBit {}

export type GeneratedPageConfig =
  | ModulePageConfig
  | IframePageConfig
  | NavigationPageConfig
export type PageConfig = DefaultPageConfig | GeneratedPageConfig

export type ConfigForType<T extends PortalPageType> =
  T extends PortalPageType.MODULE
    ? ModulePageConfig
    : T extends PortalPageType.IFRAME
    ? IframePageConfig
    : T extends PortalPageType.NAVIGATION
    ? NavigationPageConfig
    : DefaultPageConfig

export interface DefaultPage
  extends DefaultPageProperties,
    HasPermissionObject {
  type: PortalPageType.DEFAULT
}

export interface ModulePage extends ModulePageProperties, HasPermissionObject {
  type: PortalPageType.MODULE
}

export interface IframePage extends IframePageProperties, HasPermissionObject {
  type: PortalPageType.IFRAME
}

export type NavigationPage = Omit<NavigationPageProperties, 'items'> &
  HasPermissionObject & {
    items: InternalSubNavigationItem[]
    type: PortalPageType.NAVIGATION
  }

export type GeneratedPage = ModulePage | IframePage | NavigationPage
export type Page = DefaultPage | GeneratedPage
export type PageForType<T extends GeneratedPortalPageType> = Extract<
  Page,
  { type: T }
>

export interface Locale {
  key: string // ISO-639-3, ie. "deu" or "eng"
  countryAndLocaleCodes: string // (ISO-639-1)-(ISO 3166-1), ie. "de-DE" or "en-US"
  localeCode: string // ISO-639-1, ie. "de" or "en"
}

export type LocaleConfig =
  | {
      locales: Locale[]
    }
  | string[]

export interface Plugin {
  uuid: string
  name: string
  config: Record<string, unknown>
}

export interface TrackingPlugins {
  googleAnalytics?: Plugin & {
    config: {
      enabled: boolean
      trackingId: string
    }
  }
  leadInfo?: Plugin & {
    config: {
      enabled: boolean
      siteId: string
    }
  }
  matomo?: Plugin & {
    config: {
      enabled: boolean
      url: string
      siteId: number
    }
  }
  hubspot?: string
}

export interface LoginConfig {
  method?: string
}

export interface DocumentConfig {
  validateFileType: boolean
  allowedFileTypes: string[]
}

export interface PortalConfig {
  title?: string
  fuchsbau?: {
    id: string
    name: string
  }
  registrationContact?: string
  locales?: LocaleConfig
  tracking: TrackingPlugins
  login?: LoginConfig
  documents?: Partial<DocumentConfig>
  modules: ModulePageConfig[]
  iframes: IframePageConfig[]
  navigationPages: NavigationPageConfig[]
  pages: Record<string, DefaultPageConfig>
  navigation: NavigationItemConfig[]
}

export interface BasePortalConfig {
  tracking: TrackingPlugins
  documents: DocumentConfig
  pages: Record<string, DefaultPageConfig>
  navigation: NavigationItemConfig[]
}
